import React, { Component } from 'react';
import './App.css';
import SearchForm from './SearchForm';
import Results from './Results'

class App extends Component {
  
  state = {
    loading: false,
    results: null
  }

  getResults = (term, type) => {
    const BASE_URL = `https://swapi.co/api/`
    let results = {}

    fetch(`${BASE_URL}${type}/?search=${term}`)
    .then(res => {
      return res.json();
    })  
    .then(resJson =>{
      results = resJson.results
      this.setState({
        results: results
      })
      this.setLoading()
    })
}

  setLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }));
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">
          <h1>Star Wars Search Engine</h1>
        </header>
        <SearchForm handleClick={this.setLoading} getResults={this.getResults}/>
        {this.state.loading && 
          <p>Just a moment, getting your results</p>
        }
        <Results loading = {this.state.loading} results={this.state.results} />
      </div>
    );
  }
}

export default App


// To complete the exercise you need to:

// Write a React app which allows users to search the Star Wars API for a character name, and displays the matching characters
// For example, a search for "Skywalker" should list "Luke Skywalker", "Anakin Skywalker" and "Shmi Skywalker"
// The app must be deployed using Zeit's Now
// Bonus points are awarded for:

// Gracefully displaying a loading indicator, and handling error conditions
// Being able select whether you want to search for planets, spaceships, vehicles, characters, films or species
// A passing test suite