import React, { Component } from 'react'

class SearchForm extends Component {

  handleSearch = (e) => {
    let term = e.target['search-term'].value
    let type = e.target['search-type'].value
    this.props.handleClick()
    this.props.getResults(term, type)
  }

  render(){
    return(
      <main>
      <form name="starwars-search" id="search-form" 
        onSubmit={(e) => {
        e.preventDefault()
        this.handleSearch(e)}}
      >
        <label htmlFor="search-term">
          What would you like to look for?
        </label>
        <input type="text" name="search-term" required />
          <label htmlFor="search-type">
            What type of results do you want to see?
          </label>
        <select name="search-type" defaultValue="people">
          <option>planets</option>
          <option>starships</option>
          <option>vehicles</option>
          <option>people</option>
          <option>films</option>
          <option>species</option>
        </select>
        <button type="submit">Search</button>
      </form>
      </main>
    )
  }
}

export default SearchForm