import React, { Component } from 'react'


class Results extends Component {




  render() {

let results = this.props.results
    console.log(results)
    let names
  if(results){
    names = results.map(item => (
      <li key={item.name}> {item.name} </li>
      ))
      
  }


console.log(names)
    return (
      <div className="results">
        {results && results.length === 0 && 
          <p>Sorry, we couldn't find anything</p>
        }
        {!this.props.loading && results !== null &&
        <ul>
          <li key="results">Results: {results.length}</li> <p>{names}</p>
        </ul>
        }
      </div>
    )
  }
}

export default Results